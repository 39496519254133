import React, { Component } from 'react';
import Techno from './Techno';
import MobileView from './MobileView';
import './Portfolio.css'

class Portfolio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userClickMobile: false
        }
        this.userClickMobileManager = this.userClickMobileManager.bind(this)
    }

    userClickMobileManager(){
        let userClickMobile = this.state.userClickMobile
        userClickMobile = !userClickMobile
        this.setState({userClickMobile: userClickMobile})
    }

    getPortfolioImg(img, img_mobile)
    {
        let userClickMobile = this.state.userClickMobile
        if(userClickMobile && this.props.showMobile)
        {
            return "mobile/" + img_mobile;
        }
        return img;
    }

    render() {
        const {title, img, img_mobile, link, techno, showTechno, showMobile, type_work} = this.props;
        return (
            <div className="col-12 portfolio-item">
                <div className="portfolio-img">
                    <img
                        src={process.env.PUBLIC_URL + "/img/" + this.getPortfolioImg(img, img_mobile)}
                        className="img-fluid website-img"
                        alt={title}
                        title={title}
                    />
                    <a href={link} target="_blank">
                        <div className="overlay">
                            <div className="overlay-text"><strong>{title}</strong></div>
                        </div>
                    </a>
                </div>
                {showTechno && (
                    <div className="row techno">
                            {techno.map((content, key) => (
                                <Techno
                                    techno={content}
                                    key={key}
                                />
                            ))}
                    </div>
                )}
                {showMobile && (
                    <MobileView
                        userClickMobileManager = {this.userClickMobileManager}
                    />
                )}
                <div className="type-work-container">
                    <img
                        src={process.env.PUBLIC_URL + "/img/type_work/"+ type_work + ".png"}
                        className="img-fluid website-img img-type-work"
                        alt={type_work}
                        title={"Projet " + type_work}
                    />
                </div>
            </div>

        )
    }

    /*
    componentDidMount = () =>
    {
        console.log(this);
    }
    */
}

export default Portfolio