const DATA = [
    {
        "title": "AH L’atelier d’Angel",
        "img": "ah_atelier_angel.png",
        "img_mobile": "ah_atelier_angel.png",
        "link": "https://atelier-angel.fr/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Société d'Encouragement pour l'Industrie Nationale",
        "img": "sein.png",
        "img_mobile": "sein.png",
        "link": "https://industrienationale.fr/",
        "techno": ["PHP", "Wordpress", "VueJS"],
        "type_work": "professionnel",
    },
    {
        "title": "Soneva",
        "img": "soneva.png",
        "img_mobile": "soneva_M.png",
        "link": "https://soneva.fr/",
        "techno": ["PHP", "Wordpress", "VueJS"],
        "type_work": "professionnel",
    },
    {
        "title": "Édition du traité de médecine",
        "img": "tdm.png",
        "img_mobile": "tdm_M.png",
        "link": "https://www.traitemedecine.fr/",
        "techno": ["Wordpress", "Javascript", "PHP"],
        "type_work": "professionnel",
    },
    {
        "title": "Flex HS",
        "img": "flex-hs.png",
        "img_mobile": "flex-hs_M.png",
        "link": "https://flex-hs.com/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Plateforme de prise de rendez-vous de l'association Le Coup de Main Numérique",
        "img": "rdv.png",
        "img_mobile": "rdv_M.png",
        "link": "https://rdv.le-coup-de-main-numerique.org/",
        "techno": ["Django", "Python", "Javascript", "Docker"],
        "type_work": "personel",
    },
    {
        "title": "Sorare Finance",
        "img": "sorarefinance.png",
        "img_mobile": "sorarefinance_M.png",
        "link": "https://www.sorarefinance.com/",
        "techno": ["Django", "Python", "VueJS", "GraphQL", "Docker", "Flask"],
        "type_work": "professionnel",
    },
    {
        "title": "Pause Bien-Être Énergie",
        "img": "pause_energie_bien_etre.png",
        "img_mobile": "pause_energie_bien_etre_M.png",
        "link": "https://pause-bien-etre-energie.fr/",
        "techno": ["Wordpress", "Javascript"],
        "type_work": "professionnel",
    },
    {
        "title": "Intranet GFCO",
        "img": "intranet_gfco.png",
        "img_mobile": "intranet_gfco_M.png",
        "link": "https://extranet.gfco.fr/",
        "techno": ["Wordpress", "Javascript", "VueJS"],
        "type_work": "professionnel",
    },
    {
        "title": "Imex Pharma",
        "img": "imex.png",
        "img_mobile": "imex_M.png",
        "link": "https://imex-pharma.com/",
        "techno": ["Wordpress", "Javascript"],
        "type_work": "professionnel",
    },
    {
        "title": "GFCO",
        "img": "gfco.png",
        "img_mobile": "gfco_M.png",
        "link": "https://gfco.fr",
        "techno": ["Wordpress", "Javascript", "VueJS"],
        "type_work": "professionnel",
    },
    {
        "title": "HMBAT",
        "img": "hmbat.png",
        "img_mobile": "hmbat_M.png",
        "link": "https://hmbat.fr",
        "techno": ["Wordpress", "Javascript", "VueJS"],
        "type_work": "professionnel",
    },
    {
        "title": "Le pop club",
        "img": "le_pop_club.png",
        "img_mobile": "le_pop_club_M.png",
        "link": "https://www.lepopclub.fr/",
        "techno": ["Wordpress", "PHP"],
        "type_work": "professionnel",
    },
    {
        "title": "Irwino",
        "img": "irwino.png",
        "img_mobile": "irwino_M.png",
        "link": "https://www.irwino.com/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Argenterie d'Antan",
        "img": "argenterie.png",
        "img_mobile": "argenterie_M.png",
        "link": "https://www.argenterie-dantan.com/",
        "techno": ["Wordpress", "Javascript"],
        "type_work": "professionnel",
    },
    {
        "title": "4D Crea",
        "img": "4D_CREA.png",
        "img_mobile": "4D_CREA_M.png",
        "link": "https://www.4dcrea.com/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Néodance Plateforme",
        "img": "neodance-plateforme.png",
        "img_mobile": "neodance-plateforme_M.png",
        "link": "https://neodance-plateforme.com",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Néodance Academy",
        "img": "neodanceacademy.png",
        "img_mobile": "neodanceacademy_M.png",
        "link": "https://neodanceacademy.com/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Consilium Vitae",
        "img": "consiliumvitae.png",
        "img_mobile": "consiliumvitae_M.png",
        "link": "https://consiliumvitae.com/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Viapix",
        "img": "viapix.png",
        "img_mobile": "viapix_M.png",
        "link": "https://viapix.fr/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Axtrid",
        "img": "axtrid.png",
        "img_mobile": "axtrid_M.png",
        "link": "https://axtrid.fr/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Studio 16",
        "img": "studio16.png",
        "img_mobile": "studio16_M.png",
        "link": "https://studio16-architecte.com/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Genaris",
        "img": "genaris.png",
        "img_mobile": "genaris_M.png",
        "link": "https://www.genaris.fr/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Le coup de main numérique",
        "img": "ldcmn.png",
        "img_mobile": "ldcmn_M.png",
        "link": "http://le-coup-de-main-numerique.org/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "YGA Consulting",
        "img": "YGA.jpg",
        "img_mobile": "YGA_M.png",
        "link": "https://ygaconsulting.com/",
        "techno": ["Wordpress", "Javascript"],
        "type_work": "professionnel",
    },
    {
        "title": "Hackadon",
        "img": "hackadon.jpg",
        "img_mobile": "hackadon_M.jpg",
        "link": "http://www.hackadon.vaede.com/",
        "techno": ["PHP", "Javascript", "Bootstrap"],
        "type_work": "scolaire",
    },
    {
        "title": "Sarah Galvan Photographe",
        "img": "Sarah_Galvan_Photographe.jpg",
        "img_mobile": "Sarah_Galvan_Photographe_M.jpg",
        "link": "https://www.sarahgalvan.com/",
        "techno": ["Wordpress", "Javascript"],
        "type_work": "professionnel",
    },
    {
        "title": "Contact Manager",
        "img": "contact_manager.png",
        "img_mobile": "contact_manager_M.png",
        "link": "http://contact-manager.vaede.com",
        "techno": ["VueJS", "TypeScript", "Bootstrap", "PHP"],
        "type_work": "scolaire",
    },
    {
        "title": "Hogedus",
        "img": "Hogedus.png",
        "img_mobile": "Hogedus_M.png",
        "link": "https://hogedus.com",
        "techno": ["PHP", "Bootstrap", "Jquery"],
        "type_work": "professionnel",
    },
    {
        "title": "Pastilles des Savoies",
        "img": "pds.png",
        "img_mobile": "pds_M.png",
        "link": "https://pastillesdessavoies.fr/",
        "techno": ["Wordpress", "Photoshop", "Bootstrap"],
        "type_work": "professionnel",
    },
    {
        "title": "BBCDP Assurance",
        "img": "bbcdp.jpeg",
        "img_mobile": "bbcdp_M.jpeg",
        "link": "https://client-riche.vaede.com/",
        "techno": ["React", "Bootstrap", "Jquery"],
        "type_work": "scolaire",
    },
    {
        "title": "Everway",
        "img": "everway.jpeg",
        "img_mobile": "everway_M.png",
        "link": "https://everway-international.com/",
        "techno": ["Wordpress", "Photoshop"],
        "type_work": "professionnel",
    },
    {
        "title": "Le coup de main numérique (Plateforme adhérent)",
        "img": "adherent_cdmn.png",
        "img_mobile": "adherent_cdmn_M.png",
        "link": "https://adhesion.le-coup-de-main-numerique.org/",
        "techno": ["Bootstrap", "Jquery", "PHP"],
        "type_work": "personel",
    },
    {
        "title": "Rosario Nettoyage",
        "img": "rosario.jpeg",
        "img_mobile": "rosario_M.png",
        "link": "https://www.rosario-nettoyage.com/",
        "techno": ["Wordpress", "Photoshop", "Jquery", "Bootstrap"],
        "type_work": "professionnel",
    },
    {
        "title": "IUT Informatique",
        "img": "iut.jpg",
        "img_mobile": "iut_M.png",
        "link": "https://iut.vaede.com",
        "techno": ["PHP", "Bootstrap", "Jquery", "Javascript", "Photoshop"],
        "type_work": "scolaire",
    },
    {
        "title": "Olivier Mauron Institut",
        "img": "omi.jpeg",
        "img_mobile": "omi_M.jpeg",
        "link": "https://oliviermauroninstitut.com/",
        "techno": ["Wordpress", "Photoshop"],
        "type_work": "professionnel",
    },
    {
        "title": "Demander le bonheur",
        "img": "dlb.jpeg",
        "img_mobile": "dlb_M.jpeg",
        "link": "https://demanderlebonheur.com/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "MIAM",
        "img": "miam.jpg",
        "img_mobile": "miam_M.jpeg",
        "link": "https://www.miamevent.com/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Sound Discovering",
        "img": "sound_discovering.jpeg",
        "img_mobile": "sound_discovering_M.png",
        "link": "https://www.sound-discovering.com/",
        "techno": ["PHP", "Bootstrap", "Jquery", "Javascript", "Photoshop"],
        "type_work": "personel",
    },
    {
        "title": "La ligue de l'enseignement 78",
        "img": "ligue78.jpeg",
        "img_mobile": "ligue78_M.jpeg",
        "link": "https://www.laligue78.org/",
        "techno": ["Wordpress", "Photoshop"],
        "type_work": "professionnel",
    },
    {
        "title": "Escale",
        "img": "escale.jpg",
        "img_mobile": "escale_M.jpeg",
        "link": "https://www.escale.laligue78.org/",
        "techno": ["Wordpress"],
        "type_work": "professionnel",
    },
    {
        "title": "Votre futur site",
        "img": "code.jpg",
        "img_mobile": "code_M.jpeg",
        "link": "#contact",
        "techno": ["Wordpress", "PHP", "Bootstrap", "Jquery", "Javascript", "Photoshop"],
        "type_work": "professionnel",
    }
]
export default DATA
